function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return decodeURI(pair[1]);
    }
  }
  return false;
}

function isQueryVariableExist(variable) {
  return getQueryVariable(variable) !== false;
}

/**
 * Backward compatibility with old links
 */
function redirectFromOldPages() {
  if (isKioskPage()) {
    redirectTo(replacePage('kioskStore/kiosk-page.html'));
  } else if (isRideKiosk()) {
    redirectTo(replacePage('ride-kiosk.html'));
  } else if (isAdminConsole()) {
    redirectTo(replacePage('admin-console.html'));
  } else if (isOldWebapp()) {
    redirectTo(replacePage('old-webapp.html'));
  } else if (isKioskAlbumLink()) {
    redirectTo(replacePage('kiosk-album-link.html'));
  } else if (isPublicPage()) {
    redirectTo(replacePage('public-page.html'));
  } else if (isPrivatePage()) {
    redirectTo(replacePage('private-page.html'));
  }

  function isKioskPage() {
    return isQueryVariableExist('kType') && getQueryVariable('kType') !== 'evSB3';
  }

  function isOldWebapp() {
    return !isQueryVariableExist('kType') && isQueryVariableExist('attId');
  }

  function isRideKiosk() {
    return getQueryVariable('kType') === 'evSB3';
  }

  function isKioskAlbumLink() {
    return isQueryVariableExist('pId') && isQueryVariableExist('kioskMode');
  }

  function isPublicPage() {
    return isQueryVariableExist('pId');
  }

  function isPrivatePage() {
    return isQueryVariableExist('pvId');
  }

  function isAdminConsole() {
    return window.location.search === '';
  }

  function replacePage(page) {
    const currentURL = new URL(window.location.href);
    const arrayPath = currentURL.pathname.split('/');
    arrayPath[arrayPath.length - 1] = page;
    currentURL.pathname = arrayPath.join('/');
    return currentURL.href;
  }

  function redirectTo(url) {
    window.location.replace(url);
  }
}

redirectFromOldPages();
